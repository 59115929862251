import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// CSS
import 'styles/Contact.scss'

// Components
import { TitleDefault, BannerDefault, BreadCrumbBar } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactForm from 'components/ContactForm'
import GravityForm from '../components/GravityForm'

// Images
import placeholder from '../img/adres_icon.svg'
import mail from '../img/mail-icon.svg'
import phone from '../img/phone-icon.svg'
import instagram from '../img/instagram.svg'
import facebook from '../img/facebook.svg'
import twitter from '../img/twitter.svg'

function Contact({ data, pageContext }) {
  const {
    wordpressPage: {
      title,
      acf: { header_image_contact, title_contact },
      yoast_meta,
    },
    wordpressWpComponents: {
      acf: { contact, social_footer },
    },
  } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO yoast={{ meta: yoast_meta }} />
      <div className="contact-page">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="col-12 image-container p-0">
              <BackgroundImage
                fluid={header_image_contact.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="container">
              <div className="col-12 col-lg-6">
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb crumbs={crumbs} crumbSeparator="-" crumbLabel={title} />
          </div>
        </BreadCrumbBar>
        <section className="contact-row py-5">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-12 col-lg-6 mb-sm-5 mb-lg-0">
                <TitleDefault>
                  <span>{title_contact.subtitle_contact}</span>
                  <br />
                  <strong>{title_contact.title_contact}</strong>
                </TitleDefault>
                <div className="row">
                  <div className="col-md-1" />
                  <div className="col-md-11">
                    <div className="adres">
                      <img src={placeholder} alt="" width="15" />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: contact.contact_adres,
                        }}
                      />
                    </div>
                    <div className="contact">
                      <a href={`tel:${contact.phone_footer}`} className="phone">
                        <img src={phone} width="16" alt="Bel ons" />
                        {contact.phone_footer}
                      </a>
                      <a
                        href={`tel:${contact.second_phone_footer}`}
                        className="phone"
                      >
                        <img
                          src={phone}
                          width="16"
                          className="invisible"
                          alt="Bel ons"
                        />
                        {contact.second_phone_footer}
                      </a>
                      <br />
                      <a
                        href={`mailto:${contact.email_footer}`}
                        className="mail"
                      >
                        <img src={mail} width="16" alt="Mail ons" />
                        {contact.email_footer}
                      </a>
                    </div>
                    <div className="social-group">
                      <a
                        href={social_footer.instagram_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={instagram} width="28" alt="Instragram" />
                      </a>
                      <a
                        href={social_footer.facebook_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={facebook} width="28" alt="Instragram" />
                      </a>
                      <a
                        href={social_footer.twitter_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={twitter} width="28" alt="Instragram" />
                      </a>
                    </div>
                    <div className="route">
                      <p>Routebeschrijving</p>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.0437269197087!2d5.369052016348778!3d52.22442336584345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c64194c8fc4b3b%3A0xc59e0bb6de4c86df!2sSchildersbedrijf%20Koelewijn-Crookewit!5e0!3m2!1snl!2snl!4v1578649376585!5m2!1snl!2snl"
                        width="100%"
                        height="187"
                        frameBorder="0"
                        allowFullScreen=""
                        title="route"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="contact-box p-4">
                  <GravityForm id={1} />
                  <p className="pt-4 mb-0"><em>* verplichte velden</em></p>
                </div>
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default Contact

export const ContactQuery = graphql`
  query Contact {
    wordpressPage(wordpress_id: { eq: 102 }) {
      id
      title
      acf {
        header_image_contact {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title_contact {
          subtitle_contact
          title_contact
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
    wordpressWpComponents(wordpress_id: { eq: 81 }) {
      id
      acf {
        contact {
          contact_adres
          email_footer
          phone_footer
          second_phone_footer
        }
        social_footer {
          facebook_url
          instagram_url
          twitter_url
        }
      }
    }
  }
`
